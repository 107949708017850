import 'prismjs/themes/prism-tomorrow.css';
import React, { lazy, Suspense } from 'react';
import ReactDOM from 'react-dom';
import 'react-perfect-scrollbar/dist/css/styles.css';
import { Provider } from 'react-redux';
import { BrowserRouter, Switch } from 'react-router-dom';
import { PersistGate } from 'redux-persist/integration/react';
import './components/@vuexy/rippleButton/RippleButton';
import SpinnerComponent from './components/@vuexy/spinner/Fallback-spinner';

import PrivateRoute from './components/shared/PrivateRoute';
import PublicRoute from './components/shared/PublicRoute';
import ScrollToTop from './components/shared/ScrollToTop';
import CONSTANTS from './constants';
import Layout from './helpers/context/Layout';
import './index.scss';
import CashCheckout from './pos/src/components/CashCheckout';
import CheckoutDone from './pos/src/components/CheckoutDone';
import OrderList from './pos/src/components/OrderList';
import OrderDetail from './pos/src/container/order-detail';
import HomePos from './pos/src/container/pos';

import { persistor, store } from './redux/storeConfig/store';
import LoginService from './services/LoginService';
import swDev from './swDev';

const LiveOrders = lazy(() => import('./components/orders/LiveOrders'));
const MetaTagsForm = lazy(() => import('./components/metatags/MetaTagsForm'));
const Activites = lazy(() => import('./components/activities/Activities'));
const PaymentLogs = lazy(() => import('./components/paymentlogs/PaymentLogs'));
const CustomerStats = lazy(() => import('./components/analytics/CustomersList'));
const ItemsStats = lazy(() => import('./components/analytics/ItemsList'));
const BinOrders = lazy(() => import('./components/analytics/BinOrders'));
const BinsStats = lazy(() => import('./components/analytics/Bins'));

const AnalyticsOrdersList = lazy(() => import('./components/analytics/OrdersList'));
const OverallStats = lazy(() => import('./components/analytics/OverallStats'));
const RestaurantsStats = lazy(() => import('./components/analytics/RestaurantsStats'));
const AppReports = lazy(() => import('./components/appdata/reports/AppReports'));
const AppUsers = lazy(() => import('./components/appdata/users/AppUsers'));
const AppSettings = lazy(() => import('./components/appsettings/AppSettings'));
const Branding = lazy(() => import('./components/branding/Branding'));
const SmsCampaigns = lazy(() => import('./components/campaigns/SmsCampaigns'));
const SmsCampaignsForm = lazy(() => import('./components/campaigns/SmsCampaignsForm'));
const SmsNonPlugdUsers = lazy(() => import('./components/campaigns/SmsNonPlugdUsers'));
const Currency = lazy(() => import('./components/currencies/Currency'));
const CurrencyForm = lazy(() => import('./components/currencies/CurrencyForm'));
const MainOTP = lazy(() => import('./components/otp-settings/MainOTP'));
const OTPForm = lazy(() => import('./components/otp-settings/OTPForm'));
const BinBanks = lazy(() => import('./components/binbanks/BinBanks'));
const BinBanksForm = lazy(() => import('./components/binbanks/BinBanksForm'));
const Deliveries = lazy(() => import('./components/deliveries/Deliveries'));
const Departments = lazy(() => import('./components/departments/Department'));
const Department = lazy(() => import('./components/departments/Departments'));
const NewDepartment = lazy(() => import('./components/departments/NewDepartment'));
const Feedback = lazy(() => import('./components/feedback/Feedback'));
const InventoryCountForm = lazy(() => import('./components/inventory-count/InventoryCountForm'));
const InventoryStock = lazy(() => import('./components/inventory-stock/InventoryStock'));
const InventoryStockDetail = lazy(() =>
  import('./components/inventory-stock/InventoryStockDetail')
);
const EditInventoryForm = lazy(() => import('./components/inventory/EditInventoryForm'));
const Inventory = lazy(() => import('./components/inventory/Inventory'));
const InventoryForm = lazy(() => import('./components/inventory/InventoryForm'));
const AllLocations = lazy(() => import('./components/locations/AllLocations'));
const Location = lazy(() => import('./components/locations/Location'));
const Locations = lazy(() => import('./components/locations/Locations'));
const NewLocation = lazy(() => import('./components/locations/NewLocation'));
const OrderTypes = lazy(() => import('./components/locations/OrderTypes'));
const PaymentProcessor = lazy(() => import('./components/locations/PaymentProcessor'));
const RiderCompensation = lazy(() => import('./components/locations/RiderCompensation'));
const RiderServices = lazy(() => import('./components/locations/RiderServices'));
const Menu = lazy(() => import('./components/menus/Menu'));
const UploadMenu = lazy(() => import('./components/menus/UploadMenu'));
const Modifiers = lazy(() => import('./components/modifiers/Modifiers'));
const ToastifyNotification = lazy(() =>
  import('./components/notifications/toastify-noatification')
);
const Order = lazy(() => import('./components/orders/Order'));
const NewOutlet = lazy(() => import('./components/outlets/NewOutlet'));
const Outlet = lazy(() => import('./components/outlets/Outlet'));
const Outlets = lazy(() => import('./components/outlets/Outlets'));
const PrinterStations = lazy(() => import('./components/printer-stations/PrinterStations'));
const ProductionDetail = lazy(() => import('./components/productions/ProductionDetail'));
const ProductionForm = lazy(() => import('./components/productions/ProductionForm'));
const Productions = lazy(() => import('./components/productions/Productions'));
const PromotionForm = lazy(() => import('./components/promotions/PromotionForm'));
const Promotions = lazy(() => import('./components/promotions/Promotions'));
const Bindiscounts = lazy(() => import('./components/bindiscounts/Bindiscounts'));
const BindiscountForm = lazy(() => import('./components/bindiscounts/BindiscountForm'));
const PurchaseDetails = lazy(() => import('./components/purchases/PurchaseDetails'));
const PurchaseForm = lazy(() => import('./components/purchases/PurchaseForm'));
const Purchases = lazy(() => import('./components/purchases/Purchases'));
const Recipe = lazy(() => import('./components/recipe/Recipe'));
const RecipeForm = lazy(() => import('./components/recipe/RecipeForm'));
const Reviews = lazy(() => import('./components/reviews/Reviews'));
const RidersPaymentsForm = lazy(() => import('./components/rider/PaymentForm'));
const RiderHistory = lazy(() => import('./components/rider/RiderHistory'));
const RidersPayments = lazy(() => import('./components/rider/RiderPayments'));
const Riders = lazy(() => import('./components/rider/Riders'));
const EarningsDetails = lazy(() => import('./components/rider/EarningDetails'));
const Roles = lazy(() => import('./components/roles/Roles'));
const RolesForm = lazy(() => import('./components/roles/RolesForm'));
const SessionActivity = lazy(() => import('./components/session-activity/SessionActivity'));
const Login = lazy(() => import('./components/sessions/Login'));
const Logout = lazy(() => import('./components/sessions/Logout'));

const Loyalties = lazy(() => import('./components/loyalty/Loyalties'));
const LoyaltyForm = lazy(() => import('./components/loyalty/LoyaltyForm'));

const SelectLocation = lazy(() => import('./components/shared/SelectLocation'));
const AllSmsSubscriptions = lazy(() => import('./components/smsplans/admin/AllSmsSubscriptions'));
const AdminSmsPlans = lazy(() => import('./components/smsplans/AdminSmsPlans'));
const SmsPayments = lazy(() => import('./components/smsplans/SmsPayments'));
const SmsPlanForm = lazy(() => import('./components/smsplans/SmsPlanForm'));
const SmsPlans = lazy(() => import('./components/smsplans/SmsPlans'));
const SmsSubscriptions = lazy(() => import('./components/smsplans/SmsSubscriptions'));
const AssignRole = lazy(() => import('./components/staff/rolesassign/RoleAssign'));
const AssignRoleForm = lazy(() => import('./components/staff/rolesassign/RoleAssignForm'));
const Staff = lazy(() => import('./components/staff/Staff'));
const StaffForm = lazy(() => import('./components/staff/StaffForm'));
const StockAdjustmentDetail = lazy(() =>
  import('./components/stock-adjustment/StockAdjustmentDetail')
);
const StockAdjustmentForm = lazy(() => import('./components/stock-adjustment/StockAdjustmentForm'));
const StockAdjustments = lazy(() => import('./components/stock-adjustment/StockAdjustments'));
const StockCountDetails = lazy(() => import('./components/stock-counts/StockCountDetails'));
const StockCounts = lazy(() => import('./components/stock-counts/StockCounts'));
const StockTransferDetails = lazy(() =>
  import('./components/stock-transfers/StockTransferDetails')
);
const StockTranferForm = lazy(() => import('./components/stock-transfers/StockTransferForm'));
const StockTransfers = lazy(() => import('./components/stock-transfers/StockTransfers'));
const Tables = lazy(() => import('./components/tables/Tables'));
const TablesForm = lazy(() => import('./components/tables/TablesForm'));
const Terms = lazy(() => import('./components/terms/Terms'));

const AppNotificationForm = lazy(() => import('./components/appnotifications/AppNotificationForm'));
const AppNotifications = lazy(() => import('./components/appnotifications/AppNotifications'));
const FooterUrlsForm = lazy(() => import('./components/locations/FooterUrlsForm'));
const PageLayouts = lazy(() => import('./components/pagelayouts/PageLayouts'));

const ManualSmsPurchase = lazy(() => import('./components/smsplans/manual/ManualSmsPurchase'));

const AccessPackages = lazy(() => import('./components/access/AccessPackages'));
const AccessPackagesForm = lazy(() => import('./components/access/AccessPackagesForm'));
const AssignModules = lazy(() => import('./components/access/AssignModules'));
const Pages = lazy(() => import('./components/pages/Pages'));
const PagesForm = lazy(() => import('./components/pages/PageForm'));

const APP_VERSION = process.env.REACT_APP_CURRENT_GIT_SHA;
const CLEAR_STORAGE = !!process.env.REACT_APP_CLEAR_STORAGE;
if (localStorage.APP_VERSION !== APP_VERSION) {
  if (CLEAR_STORAGE) {
    localStorage.clear();
  }
  localStorage.setItem('APP_VERSION', APP_VERSION);
}

if (!LoginService.getToken()) {
  LoginService.refresh();
}

ReactDOM.render(
  <Provider store={store}>
    <PersistGate loading={null} persistor={persistor}>
      <Layout>
        <BrowserRouter>
          <ScrollToTop />
          <div className="app-container">
            <Suspense fallback={<SpinnerComponent />}>
              <Switch>
                <PublicRoute
                  fullLayout
                  exact
                  path={`${CONSTANTS.BASE_URL}login`}
                  component={Login}
                />
                <PublicRoute
                  fullLayout
                  exact
                  path={`${CONSTANTS.BASE_URL}terms`}
                  component={Terms}
                />
                <PrivateRoute
                  exact
                  path={`${CONSTANTS.BASE_URL}logout`}
                  component={Logout}
                  pageTitle={'Logout'}
                />
                <PrivateRoute
                  exact
                  path={`${CONSTANTS.BASE_URL}`}
                  component={LiveOrders}
                  pageTitle={'Live Orders'}
                />
                <PrivateRoute
                  exact
                  path={`${CONSTANTS.BASE_URL}locations/:location_id/orders/:connectivity/:order_id`}
                  component={Order}
                  pageTitle={'Order Details'}
                  goBackURL={'home'}
                />
                {/*<PrivateRoute exact path={`${CONSTANTS.BASE_URL}locations/:location_id/orders/:order_id`} component={Order} pageTitle={'Order Details'} goBackURL={'home'} />*/}
                <PrivateRoute
                  exact
                  path={`${CONSTANTS.BASE_URL}select_location`}
                  component={SelectLocation}
                  pageTitle={'Select Restaurant'}
                />
                <PrivateRoute
                  exact
                  path={`${CONSTANTS.BASE_URL}appdata/users`}
                  component={AppUsers}
                  pageTitle={'App Users'}
                />
                <PrivateRoute
                  exact
                  path={`${CONSTANTS.BASE_URL}appdata/reports`}
                  component={AppReports}
                  pageTitle={'App Reports'}
                />
                <PrivateRoute
                  exact
                  path={`${CONSTANTS.BASE_URL}feedbacks`}
                  component={Feedback}
                  pageTitle={'App Feedbacks'}
                />
                <PrivateRoute
                  exact
                  path={`${CONSTANTS.BASE_URL}currencies`}
                  component={Currency}
                  pageTitle={'Currencies'}
                />
                <PrivateRoute
                  exact
                  path={`${CONSTANTS.BASE_URL}currencies/new`}
                  component={CurrencyForm}
                  pageTitle={'Currency form'}
                  goBackURL={'currencies'}
                />
                <PrivateRoute
                  exact
                  path={`${CONSTANTS.BASE_URL}currencies/edit/:currency_id`}
                  component={CurrencyForm}
                  pageTitle={'Currency form'}
                  goBackURL={'currencies'}
                />
                <PrivateRoute
                  exact
                  path={`${CONSTANTS.BASE_URL}otp`}
                  component={MainOTP}
                  pageTitle={'OTP Settings'}
                />
                <PrivateRoute
                  exact
                  path={`${CONSTANTS.BASE_URL}otp/new`}
                  component={OTPForm}
                  pageTitle={'New OTP Settings'}
                  goBackURL={'otp'}
                />
                <PrivateRoute
                  exact
                  path={`${CONSTANTS.BASE_URL}otp/edit/:otp_id`}
                  component={OTPForm}
                  pageTitle={'Edit OTP Settings'}
                  goBackURL={'otp'}
                />
                <PrivateRoute
                  exact
                  path={`${CONSTANTS.BASE_URL}binbanks`}
                  component={BinBanks}
                  pageTitle={'Banks Information'}
                />
                <PrivateRoute
                  exact
                  path={`${CONSTANTS.BASE_URL}binbanks/new`}
                  component={BinBanksForm}
                  pageTitle={'New Bank Information'}
                  goBackURL={'binbanks'}
                />
                <PrivateRoute
                  exact
                  path={`${CONSTANTS.BASE_URL}binbanks/edit/:binbank_id`}
                  component={BinBanksForm}
                  pageTitle={'Edit Bank Information'}
                  goBackURL={'binbanks'}
                />
                <PrivateRoute
                  exact
                  path={`${CONSTANTS.BASE_URL}reviews`}
                  component={Reviews}
                  pageTitle={'Venue Reviews'}
                />
                <PrivateRoute
                  exact
                  path={`${CONSTANTS.BASE_URL}riders`}
                  component={Riders}
                  module="rider-and-delivery"
                  pageTitle={'Riders Earnings'}
                />
                <PrivateRoute
                  exact
                  path={`${CONSTANTS.BASE_URL}riders/earnings/:rider_id`}
                  component={EarningsDetails}
                  goBackURL={'riders'}
                  pageTitle={'Riders Earnings Details'}
                />
                <PrivateRoute
                  exact
                  path={`${CONSTANTS.BASE_URL}riders/history`}
                  component={RiderHistory}
                  pageTitle={'Delivery Ledger'}
                />
                <PrivateRoute
                  exact
                  path={`${CONSTANTS.BASE_URL}riders/payments/:rider_id`}
                  component={RidersPayments}
                  goBackURL={'riders'}
                  pageTitle={'Rider Payment Details'}
                />
                <PrivateRoute
                  exact
                  path={`${CONSTANTS.BASE_URL}riders/payments/new`}
                  component={RidersPaymentsForm}
                  pageTitle={'Add new Payment'}
                  goBackURL={'riders/payments'}
                />
                <PrivateRoute
                  exact
                  path={`${CONSTANTS.BASE_URL}locations/tables`}
                  component={Tables}
                  module="tables"
                  pageTitle={'Tables'}
                />
                <PrivateRoute
                  exact
                  path={`${CONSTANTS.BASE_URL}locations/tables/new`}
                  component={TablesForm}
                  goBackURL={'locations/tables'}
                  module="tables"
                  pageTitle={'Add New Tables'}
                />
                <PrivateRoute
                  exact
                  path={`${CONSTANTS.BASE_URL}locations/tables/edit/:table_id`}
                  component={TablesForm}
                  goBackURL={'locations/tables'}
                  pageTitle={'Edit Tables'}
                />
                <PrivateRoute
                  exact
                  path={`${CONSTANTS.BASE_URL}staff`}
                  component={Staff}
                  pageTitle={'Manager Accounts'}
                />
                <PrivateRoute
                  exact
                  path={`${CONSTANTS.BASE_URL}staff/assign/:id`}
                  component={AssignRole}
                  goBackURL={'staff'}
                  pageTitle={'Assign Role'}
                />
                <PrivateRoute
                  exact
                  path={`${CONSTANTS.BASE_URL}staff/assign/new/:id`}
                  component={AssignRoleForm}
                  goBackURL={'staff'}
                  pageTitle={'Assign Role'}
                />
                <PrivateRoute
                  exact
                  path={`${CONSTANTS.BASE_URL}staff/new`}
                  component={StaffForm}
                  goBackURL={'staff'}
                  pageTitle={'Staff New'}
                />
                <PrivateRoute
                  exact
                  path={`${CONSTANTS.BASE_URL}staff/edit/:id`}
                  component={StaffForm}
                  pageTitle={'Edit Staff'}
                  goBackURL={'staff'}
                  withConfirmation={true}
                />
                <PrivateRoute
                  exact
                  path={`${CONSTANTS.BASE_URL}roles`}
                  component={Roles}
                  pageTitle={'Roles'}
                />
                <PrivateRoute
                  exact
                  path={`${CONSTANTS.BASE_URL}roles/new`}
                  component={RolesForm}
                  pageTitle={'Roles form'}
                  goBackURL={'roles'}
                />
                <PrivateRoute
                  exact
                  path={`${CONSTANTS.BASE_URL}roles/edit/:id`}
                  component={RolesForm}
                  pageTitle={'Edit Role'}
                  goBackURL={'roles'}
                  withConfirmation={true}
                />
                <PrivateRoute
                  exact
                  path={`${CONSTANTS.BASE_URL}locations/order_types`}
                  component={OrderTypes}
                  pageTitle={'Order Types'}
                />
                <PrivateRoute
                  exact
                  path={`${CONSTANTS.BASE_URL}locations/rider_compensation`}
                  component={RiderCompensation}
                  module="rider-and-delivery"
                  pageTitle={'Rider Compensation'}
                />
                <PrivateRoute
                  exact
                  path={`${CONSTANTS.BASE_URL}locations/overall_stats`}
                  component={OverallStats}
                  module={'analytics'}
                  pageTitle={'Overall Stats'}
                />
                <PrivateRoute
                  exact
                  path={`${CONSTANTS.BASE_URL}locations/restaurants_stats`}
                  component={RestaurantsStats}
                  pageTitle={'Restaurants Stats'}
                />
                <PrivateRoute
                  exact
                  path={`${CONSTANTS.BASE_URL}locations/orders_stats`}
                  component={AnalyticsOrdersList}
                  pageTitle={'Order Logs'}
                  module="analytics"
                />
                <PrivateRoute
                  exact
                  path={`${CONSTANTS.BASE_URL}locations/customer_stats`}
                  component={CustomerStats}
                  module="analytics"
                  pageTitle={'Customer Stats'}
                />
                <PrivateRoute
                  exact
                  path={`${CONSTANTS.BASE_URL}locations/item_stats`}
                  component={ItemsStats}
                  module="analytics"
                  pageTitle={'Item Stats'}
                />
                <PrivateRoute
                  exact
                  path={`${CONSTANTS.BASE_URL}locations/bin_orders`}
                  component={BinOrders}
                  module="bin-discounts"
                  pageTitle={'Bank Discounts Logs'}
                />
                <PrivateRoute
                  exact
                  path={`${CONSTANTS.BASE_URL}locations/bin_stats`}
                  component={BinsStats}
                  module="bin-discounts"
                  pageTitle={'Bank Discounts Reports'}
                />
                <PrivateRoute
                  exact
                  path={`${CONSTANTS.BASE_URL}locations/menu`}
                  component={Menu}
                  module="menu"
                  pageTitle={'Menu'}
                />
                <PrivateRoute
                  exact
                  path={`${CONSTANTS.BASE_URL}locations/upload-menu`}
                  component={UploadMenu}
                  module="menu"
                  pageTitle={'Upload Menu'}
                />
                <PrivateRoute
                  exact
                  path={`${CONSTANTS.BASE_URL}locations/modifiers`}
                  component={Modifiers}
                  pageTitle={'Modifiers'}
                />
                <PrivateRoute
                  exact
                  path={`${CONSTANTS.BASE_URL}locations/pos`}
                  module="pos"
                  component={HomePos}
                />
                <PrivateRoute
                  exact
                  path={`${CONSTANTS.BASE_URL}locations/settings/notification`}
                  component={ToastifyNotification}
                  pageTitle={'Notification Settings'}
                />
                <PrivateRoute
                  exact
                  path={`${CONSTANTS.BASE_URL}locations/pos/cash-checkout`}
                  component={CashCheckout}
                  pageTitle={'Cash Checkout'}
                />
                <PrivateRoute
                  exact
                  path={`${CONSTANTS.BASE_URL}locations/pos/checkout-done`}
                  component={CheckoutDone}
                  pageTitle={'Checkout Done'}
                />
                <PrivateRoute
                  exact
                  path={`${CONSTANTS.BASE_URL}locations/pos/order-list`}
                  component={OrderList}
                  pageTitle={'Checkout Done'}
                />
                <PrivateRoute
                  exact
                  path={`${CONSTANTS.BASE_URL}locations/pos/order-detail/:id`}
                  component={OrderDetail}
                  pageTitle={'Order Detail'}
                  goBackURL={'locations/pos/order-list'}
                />
                <PrivateRoute
                  exact
                  path={`${CONSTANTS.BASE_URL}locations/outlets`}
                  component={Outlets}
                  module="restaurant-settings"
                  pageTitle={'Outlets'}
                />
                <PrivateRoute
                  exact
                  path={`${CONSTANTS.BASE_URL}locations/printer_stations`}
                  component={PrinterStations}
                  module="restaurant-settings"
                  pageTitle={'Printer Setting'}
                />
                <PrivateRoute
                  exact
                  path={`${CONSTANTS.BASE_URL}locations/departments`}
                  component={Departments}
                  module="restaurant-settings"
                  pageTitle={'Departments'}
                />
                <PrivateRoute
                  exact
                  path={`${CONSTANTS.BASE_URL}locations/departments/new`}
                  component={NewDepartment}
                  pageTitle={'New Department'}
                  module="restaurant-settings"
                  goBackURL={'locations/departments'}
                  withConfirmation={true}
                />
                <PrivateRoute
                  exact
                  path={`${CONSTANTS.BASE_URL}locations/deliveries`}
                  component={Deliveries}
                  pageTitle={'Deliveries'}
                />
                <PrivateRoute
                  exact
                  path={`${CONSTANTS.BASE_URL}locations/outlets/new`}
                  component={NewOutlet}
                  pageTitle={'New Outlet'}
                  module="restaurant-settings"
                  goBackURL={'locations/outlets'}
                  withConfirmation={true}
                />

                <PrivateRoute
                  exact
                  path={`${CONSTANTS.BASE_URL}locations/outlets/:location_id`}
                  component={Outlet}
                  pageTitle={'Edit Outlet'}
                  module="restaurant-settings"
                  goBackURL={'locations/outlets'}
                  withConfirmation={true}
                />

                <PrivateRoute
                  exact
                  path={`${CONSTANTS.BASE_URL}locations/departments/:location_id`}
                  component={Department}
                  pageTitle={'Edit Department'}
                  module="restaurant-settings"
                  goBackURL={'locations/departments'}
                  withConfirmation={true}
                />
                <PrivateRoute
                  exact
                  path={`${CONSTANTS.BASE_URL}locations/branding`}
                  module="restaurant-settings"
                  component={Branding}
                  pageTitle={'Branding'}
                />
                <PrivateRoute
                  exact
                  path={`${CONSTANTS.BASE_URL}locations/inventory`}
                  component={Inventory}
                  module="inventory"
                  pageTitle={'Inventory'}
                />
                <PrivateRoute
                  exact
                  path={`${CONSTANTS.BASE_URL}locations/inventory-count/new`}
                  component={InventoryCountForm}
                  pageTitle={'Create new Inventory Count'}
                  goBackURL={'locations/stock-counts'}
                  module="inventory"
                  withConfirmation={true}
                />
                <PrivateRoute
                  exact
                  path={`${CONSTANTS.BASE_URL}locations/inventory/new`}
                  component={InventoryForm}
                  module="inventory"
                  pageTitle={'Create new Inventory Item'}
                  goBackURL={'locations/inventory'}
                  withConfirmation={true}
                />
                <PrivateRoute
                  exact
                  path={`${CONSTANTS.BASE_URL}locations/inventory/:inventoryItemId/edit`}
                  component={EditInventoryForm}
                  pageTitle={'Edit Inventory Item'}
                  goBackURL={'locations/inventory'}
                  withConfirmation={true}
                />
                <PrivateRoute
                  exact
                  path={`${CONSTANTS.BASE_URL}locations/recipe`}
                  component={Recipe}
                  module="inventory"
                  pageTitle={'Recipe'}
                />
                <PrivateRoute
                  exact
                  path={`${CONSTANTS.BASE_URL}locations/recipe/new`}
                  component={RecipeForm}
                  pageTitle={'Create new Recipe'}
                  module="inventory"
                  goBackURL={'locations/recipe'}
                  withConfirmation={true}
                />
                <PrivateRoute
                  exact
                  path={`${CONSTANTS.BASE_URL}locations/recipe/:recipeId/edit`}
                  component={RecipeForm}
                  pageTitle={'Edit Recipe'}
                  goBackURL={'locations/recipe'}
                  withConfirmation={true}
                />
                <PrivateRoute
                  exact
                  path={`${CONSTANTS.BASE_URL}locations/inventory-stock`}
                  component={InventoryStock}
                  module="inventory"
                  pageTitle={'Inventory Stocks'}
                />
                <PrivateRoute
                  exact
                  path={`${CONSTANTS.BASE_URL}locations/inventory-stock/:id`}
                  component={InventoryStockDetail}
                  pageTitle={'Inventory Stock History'}
                  module="inventory"
                  goBackURL={'locations/inventory-stock'}
                />
                <PrivateRoute
                  exact
                  path={`${CONSTANTS.BASE_URL}locations/stock-adjustment`}
                  component={StockAdjustments}
                  module="inventory"
                  pageTitle={'Stock Adjustments'}
                />
                <PrivateRoute
                  exact
                  path={`${CONSTANTS.BASE_URL}locations/stock-adjustments/new`}
                  component={StockAdjustmentForm}
                  pageTitle={'Adjust Inventory Stock'}
                  module="inventory"
                  goBackURL={'locations/stock-adjustment'}
                  withConfirmation={true}
                />
                <PrivateRoute
                  exact
                  path={`${CONSTANTS.BASE_URL}locations/stock-adjustments/edit/:id`}
                  component={StockAdjustmentForm}
                  pageTitle={'Adjust Inventory Stock'}
                  module="inventory"
                  goBackURL={'locations/stock-adjustment'}
                  withConfirmation={true}
                />
                <PrivateRoute
                  exact
                  path={`${CONSTANTS.BASE_URL}locations/stock-adjustments/:id`}
                  component={StockAdjustmentDetail}
                  pageTitle={'Adjustment Detail'}
                  goBackURL={'locations/stock-adjustment'}
                />
                <PrivateRoute
                  exact
                  path={`${CONSTANTS.BASE_URL}locations/productions`}
                  component={Productions}
                  module="inventory"
                  pageTitle={'Productions'}
                />
                <PrivateRoute
                  exact
                  path={`${CONSTANTS.BASE_URL}locations/productions/new`}
                  component={ProductionForm}
                  pageTitle={'Create new Production'}
                  module="inventory"
                  goBackURL={'locations/productions'}
                  withConfirmation={true}
                />
                <PrivateRoute
                  exact
                  path={`${CONSTANTS.BASE_URL}locations/productions/:id`}
                  component={ProductionDetail}
                  pageTitle={'Production Detail'}
                  module="inventory"
                  goBackURL={'locations/productions'}
                />
                <PrivateRoute
                  exact
                  path={`${CONSTANTS.BASE_URL}locations/purchases`}
                  component={Purchases}
                  module="inventory"
                  pageTitle={'Purchases'}
                />
                <PrivateRoute
                  exact
                  path={`${CONSTANTS.BASE_URL}locations/purchases/new`}
                  component={PurchaseForm}
                  pageTitle={'Create new Purchase'}
                  goBackURL={'locations/purchases'}
                  module="inventory"
                  withConfirmation={true}
                />
                <PrivateRoute
                  exact
                  path={`${CONSTANTS.BASE_URL}locations/purchases/:id`}
                  component={PurchaseDetails}
                  goBackURL={'locations/purchases'}
                  pageTitle={'Purchase Details'}
                />
                <PrivateRoute
                  exact
                  path={`${CONSTANTS.BASE_URL}locations/stock-transfers`}
                  component={StockTransfers}
                  module="inventory"
                  pageTitle={'Stock Transfers'}
                />
                <PrivateRoute
                  exact
                  path={`${CONSTANTS.BASE_URL}locations/stock-counts`}
                  module="inventory"
                  component={StockCounts}
                  pageTitle={'Stock Counts'}
                />
                <PrivateRoute
                  exact
                  path={`${CONSTANTS.BASE_URL}locations/stock-counts/:id`}
                  component={StockCountDetails}
                  goBackURL={'locations/stock-counts'}
                  pageTitle={'Stock Count Details'}
                />
                <PrivateRoute
                  exact
                  path={`${CONSTANTS.BASE_URL}locations/stock-transfer/new`}
                  component={StockTranferForm}
                  pageTitle={'Transfer Inventory Stock'}
                  goBackURL={'locations/stock-transfers'}
                  module="inventory"
                  withConfirmation={true}
                />

                <PrivateRoute
                  exact
                  path={`${CONSTANTS.BASE_URL}locations/loyalties`}
                  component={Loyalties}
                  pageTitle={'Loyalties'}
                  module="loyalty"
                />
                <PrivateRoute
                  exact
                  path={`${CONSTANTS.BASE_URL}locations/loyalties/new`}
                  component={LoyaltyForm}
                  pageTitle={'Create new Loyalty'}
                  goBackURL={'locations/loyalties'}
                  withConfirmation={true}
                  module="loyalty"
                />
                <PrivateRoute
                  exact
                  path={`${CONSTANTS.BASE_URL}locations/loyalties/edit/:id`}
                  component={LoyaltyForm}
                  pageTitle={'Edit Loyalty'}
                  goBackURL={'locations/loyalties'}
                  withConfirmation={true}
                  module="loyalty"
                />

                <PrivateRoute
                  exact
                  path={`${CONSTANTS.BASE_URL}locations/stock-transfers/:id`}
                  component={StockTransferDetails}
                  goBackURL={'locations/stock-transfers'}
                  pageTitle={'Stock Transfer Details'}
                />
                <PrivateRoute
                  exact
                  path={`${CONSTANTS.BASE_URL}locations/promotions`}
                  component={Promotions}
                  pageTitle={'Promotions'}
                  module="promotions"
                />
                <PrivateRoute
                  exact
                  path={`${CONSTANTS.BASE_URL}locations/promotions/new`}
                  component={PromotionForm}
                  pageTitle={'Create new Promotion'}
                  goBackURL={'locations/promotions'}
                  withConfirmation={true}
                  module="promotions"
                />
                <PrivateRoute
                  exact
                  path={`${CONSTANTS.BASE_URL}locations/promotions/edit/:id`}
                  component={PromotionForm}
                  pageTitle={'Edit Promotion'}
                  goBackURL={'locations/promotions'}
                  withConfirmation={true}
                  module="promotions"
                />
                <PrivateRoute
                  exact
                  path={`${CONSTANTS.BASE_URL}locations/bindiscounts`}
                  component={Bindiscounts}
                  pageTitle={'Bank Discounts'}
                  module="bin-discounts"
                />
                <PrivateRoute
                  exact
                  path={`${CONSTANTS.BASE_URL}locations/bindiscounts/new`}
                  component={BindiscountForm}
                  pageTitle={'Create Bin Discount'}
                  goBackURL={'locations/bindiscounts'}
                  withConfirmation={true}
                  module="bin-discounts"
                />
                <PrivateRoute
                  exact
                  path={`${CONSTANTS.BASE_URL}locations/bindiscounts/edit/:id`}
                  component={BindiscountForm}
                  pageTitle={'Edit Bin Discount'}
                  goBackURL={'locations/bindiscounts'}
                  withConfirmation={true}
                  module="bin-discounts"
                />
                <PrivateRoute
                  exact
                  path={`${CONSTANTS.BASE_URL}locations/sms/campaigns`}
                  component={SmsCampaigns}
                  module="sms-marketing"
                  pageTitle={'SMS Campaigns'}
                />
                <PrivateRoute
                  exact
                  path={`${CONSTANTS.BASE_URL}locations/sms/campaigns/new`}
                  component={SmsCampaignsForm}
                  module="sms-marketing"
                  pageTitle={'Create new campaign'}
                  goBackURL={'locations/sms/campaigns'}
                  withConfirmation={true}
                />
                <PrivateRoute
                  exact
                  path={`${CONSTANTS.BASE_URL}locations/sms/campaigns/edit/:id`}
                  component={SmsCampaignsForm}
                  pageTitle={'Edit Campaign'}
                  module="sms-marketing"
                  goBackURL={'locations/sms/campaigns'}
                  withConfirmation={true}
                />

                <PrivateRoute
                  exact
                  path={`${CONSTANTS.BASE_URL}locations/sms/plans`}
                  component={SmsPlans}
                  module="sms-marketing"
                  pageTitle={'SMS Plans'}
                />
                <PrivateRoute
                  exact
                  path={`${CONSTANTS.BASE_URL}locations/sms/subscriptions`}
                  component={SmsSubscriptions}
                  module="sms-marketing"
                  pageTitle={'SMS Subscriptions'}
                />
                <PrivateRoute
                  exact
                  path={`${CONSTANTS.BASE_URL}locations/sms/plans/payment/:id`}
                  component={SmsPayments}
                  module="sms-marketing"
                  pageTitle={'SMS Plan Payments'}
                />
                <PrivateRoute
                  exact
                  path={`${CONSTANTS.BASE_URL}locations/sms/admin/plans`}
                  component={AdminSmsPlans}
                  module="sms-marketing"
                  pageTitle={'SMS Plans'}
                />
                <PrivateRoute
                  exact
                  path={`${CONSTANTS.BASE_URL}locations/sms/admin/plans/new`}
                  component={SmsPlanForm}
                  pageTitle={'SMS Plans'}
                  module="sms-marketing"
                  goBackURL={'locations/sms/admin/plans'}
                />
                <PrivateRoute
                  exact
                  path={`${CONSTANTS.BASE_URL}locations/sms/admin/plans/edit/:id`}
                  component={SmsPlanForm}
                  pageTitle={'Edit Sms Plan'}
                  goBackURL={'locations/sms/admin/plans'}
                  module="sms-marketing"
                  withConfirmation={true}
                />
                <PrivateRoute
                  exact
                  path={`${CONSTANTS.BASE_URL}locations/sms/admin/subscriptions`}
                  component={AllSmsSubscriptions}
                  module="sms-marketing"
                  pageTitle={'SMS Subscriptions'}
                />
                <PrivateRoute
                  exact
                  path={`${CONSTANTS.BASE_URL}locations/sms/admin/manual`}
                  component={ManualSmsPurchase}
                  module="sms-marketing"
                  pageTitle={'Manual SMS Purchase'}
                />
                <PrivateRoute
                  exact
                  path={`${CONSTANTS.BASE_URL}locations/payment_processor`}
                  component={PaymentProcessor}
                  pageTitle={'Payment Settings'}
                />
                <PrivateRoute
                  exact
                  path={`${CONSTANTS.BASE_URL}locations/rider_services`}
                  component={RiderServices}
                  pageTitle={'Rider Services'}
                />
                <PrivateRoute
                  exact
                  path={`${CONSTANTS.BASE_URL}locations/footer_urls`}
                  component={FooterUrlsForm}
                  pageTitle={'Footer URLs'}
                />
                <PrivateRoute
                  exact
                  path={`${CONSTANTS.BASE_URL}locations/pagelayouts`}
                  component={PageLayouts}
                  module="restaurant-settings"
                  pageTitle={'Page Layouts'}
                />

                <PrivateRoute
                  exact
                  path={`${CONSTANTS.BASE_URL}locations/pages`}
                  component={Pages}
                  pageTitle={'Pages'}
                  module="web-pages"
                />
                <PrivateRoute
                  exact
                  path={`${CONSTANTS.BASE_URL}locations/pages/create`}
                  component={PagesForm}
                  module="web-pages"
                  pageTitle={'Create new page'}
                  goBackURL={'locations/pages'}
                />
                <PrivateRoute
                  exact
                  path={`${CONSTANTS.BASE_URL}locations/pages/edit/:id`}
                  component={PagesForm}
                  pageTitle={'Edit page'}
                  module="web-pages"
                  goBackURL={'locations/pages'}
                />
                <PrivateRoute
                  exact
                  path={`${CONSTANTS.BASE_URL}locations`}
                  component={Locations}
                  pageTitle={'Select Restaurant'}
                />
                <PrivateRoute
                  exact
                  path={`${CONSTANTS.BASE_URL}locations/all`}
                  component={AllLocations}
                  pageTitle={'Restaurants'}
                />
                <PrivateRoute
                  exact
                  path={`${CONSTANTS.BASE_URL}locations/new`}
                  component={NewLocation}
                  pageTitle={'New Restaurant'}
                  goBackURL={'locations'}
                  withConfirmation={true}
                />
                <PrivateRoute
                  exact
                  path={`${CONSTANTS.BASE_URL}appdata/session-activity`}
                  component={SessionActivity}
                  pageTitle={'Session Activity'}
                />
                <PrivateRoute
                  exact
                  path={`${CONSTANTS.BASE_URL}locations/:location_id`}
                  component={Location}
                  pageTitle={'Restaurant Details'}
                  goBackURL={'locations'}
                  withConfirmation={true}
                />
                <PrivateRoute
                  exact
                  path={`${CONSTANTS.BASE_URL}appsettings`}
                  component={AppSettings}
                  pageTitle={'AppSettings'}
                />
                <PrivateRoute
                  exact
                  path={`${CONSTANTS.BASE_URL}activities`}
                  component={Activites}
                  pageTitle={'Activities'}
                />
                <PrivateRoute
                  exact
                  path={`${CONSTANTS.BASE_URL}locations/payment/logs`}
                  component={PaymentLogs}
                  pageTitle={'Payment Logs'}
                />
                <PrivateRoute
                  exact
                  path={`${CONSTANTS.BASE_URL}metatags`}
                  component={MetaTagsForm}
                  module="restaurant-settings"
                  pageTitle={'Meta Tags'}
                />
                <PrivateRoute
                  exact
                  path={`${CONSTANTS.BASE_URL}locations/app/notifications`}
                  component={AppNotificationForm}
                  module="notifications"
                  pageTitle={'App Notifications'}
                />
                <PrivateRoute
                  exact
                  path={`${CONSTANTS.BASE_URL}locations/app/notifications/logs`}
                  component={AppNotifications}
                  module="notifications"
                  pageTitle={'App Notifications Logs'}
                />
                <PrivateRoute
                  exact
                  path={`${CONSTANTS.BASE_URL}access/packages`}
                  component={AccessPackages}
                  pageTitle={'Access Modules Packages'}
                />
                <PrivateRoute
                  exact
                  path={`${CONSTANTS.BASE_URL}access/packages/create`}
                  component={AccessPackagesForm}
                  pageTitle={'Create new package'}
                  goBackURL={'access/packages'}
                />
                <PrivateRoute
                  exact
                  path={`${CONSTANTS.BASE_URL}access/packages/edit/:id`}
                  component={AccessPackagesForm}
                  pageTitle={'Edit package'}
                  goBackURL={'access/packages'}
                />
                <PrivateRoute
                  exact
                  path={`${CONSTANTS.BASE_URL}access/assign`}
                  component={AssignModules}
                  pageTitle={'Assign Module'}
                />
              </Switch>
            </Suspense>
          </div>
        </BrowserRouter>
      </Layout>
    </PersistGate>
  </Provider>,
  document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
//serviceWorker.unregister();

swDev();
