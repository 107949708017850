import Snackbar from '@material-ui/core/Snackbar';
import MuiAlert from '@material-ui/lab/Alert';
import React from 'react';
import { Col, Row } from 'react-bootstrap';
import { isAndroid, isIOS } from 'react-device-detect';
import ReactHowler from 'react-howler';
import { connect } from 'react-redux';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Swal from 'sweetalert2';
import CloseNotificationIcon from '../../assets/img/static/notification_closeIcon.svg';
import SoundIcon from '../../assets/img/static/notification_soundIcon.svg';
import themeConfig from '../../configs/themeConfig';
import CONSTANTS from '../../constants';
import FullLayout from '../../layouts/FullpageLayout';
import HorizontalLayout from '../../layouts/HorizontalLayout';
import VerticalLayout from '../../layouts/VerticalLayout';

import SyncHelper from '../../pos/src/utils/SyncHelper';
import {
  setCount,
  setMode,
  setOnPlay,
  setOneCard,
  setVolume
} from '../../redux/actions/notifications';
import { getLiveOrders, setStateValue } from '../../redux/actions/orders';
import CacheService from '../../services/CacheService';
import LocationService from '../../services/LocationService';
import SocketService from '../../services/SocketService';
import { StyledLayout } from '../../styles/GlobalStyles';

const DEFAULT_FILTER = CONSTANTS.FILTERS.ORDERS.find((filter) => filter.default);
const DEFAULT_SORT = CONSTANTS.SORTS.ORDERS.find((sort) => sort.default);
let Sound;

toast.configure();

function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}

const layouts = {
  vertical: VerticalLayout,
  full: FullLayout,
  horizontal: HorizontalLayout
};

const ContextLayout = React.createContext();

class Layout extends React.Component {
  constructor(props) {
    super(props);
    this.toastId = React.createRef();
    // this.callNotificationBtnRef = React.createRef();
    this.state = {
      activeLayout: themeConfig.layout,
      width: window.innerWidth,
      lastLayout: null,
      direction: themeConfig.direction,
      pathname: window.location.pathname,
      connection: window.navigator.onLine,
      openOnlineNotification: false,
      notificationMsg: '',
      notificationSeverity: '',
      isSilent: false,
      audioRepeat: false,
      count: 1,
      howler_sound_loop: false
    };
  }

  dismissNotification() {
    //window.Howler.mute(true);
    this.props.setOnPlay(false);
    this.props.setOneCard(false);
    this.props.setCount(1);
    toast.dismiss(this.toastId.current);
  }

  async handleRouter() {
    let lastFilterKey = localStorage.getItem('last_filter');
    let filter = {
      filter: lastFilterKey ? lastFilterKey : DEFAULT_FILTER.key,
      sort: DEFAULT_SORT.key,
      page: 0,
      filterKeyName: lastFilterKey ? lastFilterKey : 'live_orders',
      offlineOrder: false,
      connection: window.navigator.onLine,
      offlineOrderLoading: false,
      offlineOrderNotificationMsg: '',
      offlineOrderNotificationSeverity: '',
      skeleton: false,
      lastUpdated: new Date()
    };

    CacheService.setFilterData(filter);
    localStorage.setItem('last_filter', '');
    window.location.href = '/';
  }

  mute() {
    this.props.setOnPlay(false);
    this.props.setOneCard(false);
    this.props.setCount(1);
    toast.dismiss(this.toastId.current);
  }

  updatePathname() {
    this.setState({ pathname: window.location.pathname });
  }

  updateWidth() {
    this.setState({ width: window.innerWidth });
  }

  handleWindowResize() {
    this.updateWidth();
    if (this.state.activeLayout === 'horizontal' && this.state.width <= 1199)
      this.setState({ activeLayout: 'vertical', lastLayout: 'horizontal' });

    if (this.state.lastLayout === 'horizontal' && this.state.width >= 1199)
      this.setState({ activeLayout: 'horizontal', lastLayout: 'vertical' });
  }

  async updateOnlineStatus() {
    this.setState({
      openOnlineNotification: true,
      notificationMsg: 'You in online mode',
      notificationSeverity: 'success'
    });
  }

  updateOfflineStatus() {
    this.mute();
    this.setState({
      openOnlineNotification: true,
      notificationMsg: 'You are in offline mode',
      notificationSeverity: 'warning'
    });
  }

  componentDidMount() {
    if (!this.props.volume) {
      this.props.setVolume('notification_medium.wav');
      this.props.setMode('Single');
    }
    this.props.setOnPlay(false);
    this.props.setOneCard(false);
    this.props.setCount(1);

    window.addEventListener('online', this.updateOnlineStatus.bind(this));
    window.addEventListener('offline', this.updateOfflineStatus.bind(this));
    window.addEventListener('beforeunload', this.mute.bind(this));

    if (window !== undefined) window.addEventListener('resize', this.handleWindowResize.bind(this));
    this.handleDirUpdate();
    if (this.state.activeLayout === 'horizontal' && this.state.width <= 1199)
      this.setState({ activeLayout: 'vertical' });
    else if (themeConfig.layout === 'horizontal' && this.state.width >= 1200)
      this.setState({ activeLayout: 'horizontal' });
    else this.setState({ activeLayout: 'vertical' });
    SocketService.listenTo(this.socketEvents());
  }

  async componentWillUnmount() {
    //await this.mute();
    // SocketService.turnOff(this.socketEvents());
  }

  socketEvents() {
    return [
      {
        key: 'connect',
        callback: () => console.log('Socket sound connection was established')
      },
      {
        key: 'disconnect',
        callback: () => console.log('Socket sound connection was disconnected')
      },
      {
        key: 'reconnect',
        callback: () => {
          console.log('Socket reconnnect connection was disconnected');
          this.callToastifyNotification.bind(this);
        }
      },
      {
        key: CONSTANTS.VARIABLES.ORDERS.EVENTS.NEW,
        callback: this.callToastifyNotification.bind(this)
      },
      {
        key: CONSTANTS.VARIABLES.ORDERS.EVENTS.BILL_REQUESTED,
        callback: this.callToastifyNotification.bind(this)
      },
      {
        key: CONSTANTS.VARIABLES.ORDERS.EVENTS.UPDATE,
        callback: this.callToastifyNotification.bind(this)
      },
      {
        key: CONSTANTS.VARIABLES.ORDERS.EVENTS.SYNC,
        callback: this.syncData.bind(this)
      }
    ];
  }
  async syncData(data) {
    if (window.location.pathname === '/locations/pos') {
      Swal.fire({
        title: 'Fetching data',
        html: 'Please wait...',
        didOpen: () => {
          Swal.showLoading();
        }
      }).then((result) => {});
    }
    let location = await LocationService.getLocationFromServer(data.loc_id);
    if (data.type === 'menu') {
      await SyncHelper.getMenuBySyncTime(location);
    }
    if (data.type === 'promotion') {
      await SyncHelper.getPromotionsBySyncTime(location);
    }
    if (data.type === 'table') {
      await SyncHelper.getTableBySyncTime(location);
    }
    if (window.location.pathname === '/locations/pos') {
      Swal.close();
      window.location.reload();
    }
  }
  callToastifyNotification(data) {
    let user_id = localStorage.getItem(`${CONSTANTS.CACHE_KEY_PREFIX}_login_user_id`);
    let filterData = CacheService.getFilterData();
    let lastFilterKey = localStorage.getItem('last_filter');
    let filter = {
      filter: lastFilterKey ? lastFilterKey : DEFAULT_FILTER.key,
      sort: DEFAULT_SORT.key,
      page: 0,
      filterKeyName: lastFilterKey ? lastFilterKey : 'live_orders',
      offlineOrder: false,
      connection: window.navigator.onLine,
      offlineOrderLoading: false,
      offlineOrderNotificationMsg: '',
      offlineOrderNotificationSeverity: '',
      skeleton: false,
      lastUpdated: new Date()
    };

    filter = filterData ? filterData : filter;
    this.props.getLiveOrders(filter, false).then(() => {
      let order =
        this.props.orders && this.props.orders.find((p) => p.order_id === parseInt(data.order_id));
      if (
        order &&
        (order.status_id === 11 ||
          (order.order_variant === 'pos' && order.status_id === 13 && user_id !== data.pos_user_id))
      ) {
        if (isAndroid) {
        } else if (isIOS) {
        } else {
          if (this.props.handleOneCard === false) {
            if (this.props.mode !== 'Silent' && !this.props.howler_sound_play) {
              this.props.setOnPlay(true);
              this.props.setOneCard(true);
              this.props.setCount(1);
              if (this.props.mode !== 'Repeated') {
                setTimeout(() => {
                  this.props.setOnPlay(false);
                }, 3000);
              }
            }
            this.toastId.current = toast(
              <Row style={{ zIndex: '1020' }}>
                <Col
                  onClick={this.handleRouter.bind(this)}
                  className="Toastify__toast Toastify__toast-body toast-text">
                  You have {this.props.count} new order!
                </Col>
                <Col>
                  <button
                    onClick={this.dismissNotification.bind(this)}
                    className="toast-button toast-button1">
                    <img src={CloseNotificationIcon} />
                  </button>
                </Col>
                <Col>
                  <button onClick={this.mute.bind(this)} className="toast-button toast-button2">
                    <img src={SoundIcon} />
                  </button>
                </Col>
              </Row>,

              {
                containerId: 'notification',
                className: 'custom-toast',
                closeOnClick: false,
                onClose: (props) => {
                  this.setState(
                    {
                      ...this.state,
                      howler_sound_loop: this.props.mode === 'Repeated' ? true : false
                    },
                    () => {
                      this.props.setOnPlay(false);
                      this.props.setOneCard(false);
                      this.props.setCount(1);
                    }
                  );
                },
                autoClose: false
              }
            );
            this.props.setOneCard(true);
            this.props.setCount(this.props.count + 1);
          } else {
            if (this.toastId.current === null) {
              this.toastId.current = toast(
                <Row style={{ zIndex: '1020' }}>
                  <Col
                    onClick={this.handleRouter.bind(this)}
                    className="Toastify__toast Toastify__toast-body toast-text">
                    You have {this.props.count} new order!
                  </Col>
                  <Col>
                    <button
                      onClick={this.dismissNotification.bind(this)}
                      className="toast-button toast-button1">
                      <img src={CloseNotificationIcon} />
                    </button>
                  </Col>
                  <Col>
                    <button onClick={this.mute.bind(this)} className="toast-button toast-button2">
                      <img src={SoundIcon} />
                    </button>
                  </Col>
                </Row>,

                {
                  containerId: 'notification',
                  className: 'custom-toast',
                  closeOnClick: false,
                  onClose: (props) => {
                    this.setState(
                      {
                        ...this.state,
                        howler_sound_loop: this.props.mode === 'Repeated' ? true : false
                      },
                      () => {
                        this.props.setOnPlay(false);
                        this.props.setOneCard(false);
                        this.props.setCount(1);
                      }
                    );
                  },
                  autoClose: false
                }
              );
            } else {
              toast.update(this.toastId.current, {
                render: (
                  <Row style={{ zIndex: '1020' }}>
                    <Col
                      onClick={this.handleRouter.bind(this)}
                      className="Toastify__toast Toastify__toast-body toast-text">
                      {' '}
                      You have {this.props.count} new orders!
                    </Col>
                    <Col>
                      <button
                        onClick={this.dismissNotification.bind(this)}
                        className="toast-button toast-button1">
                        <img src={CloseNotificationIcon} />
                      </button>
                    </Col>
                    <Col>
                      <button onClick={this.mute.bind(this)} className="toast-button toast-button2">
                        <img src={SoundIcon} />
                      </button>
                    </Col>
                  </Row>
                ),
                autoClose: false,
                onClose: (props) => {
                  this.setState(
                    {
                      howler_sound_loop: this.props.mode === 'Repeated' ? true : false
                    },
                    () => {
                      this.props.setOnPlay(false);
                      this.props.setOneCard(false);
                      this.props.setCount(1);
                    }
                  );
                }
              });
            }
            this.props.setOnPlay(true);
            this.props.setOneCard(true);
            this.props.setCount(this.props.count + 1);
            if (this.props.mode !== 'Repeated') {
              setTimeout(() => {
                this.props.setOnPlay(false);
              }, 3000);
            }
          }
        }
      }
    });
  }

  handleNotificationClose() {
    this.setState({
      openOnlineNotification: false
    });
  }

  componentDidUpdate() {
    this.handleDirUpdate();
  }

  handleDirUpdate() {
    let dir = this.state.direction;
    if (dir === 'rtl') document.getElementsByTagName('html')[0].setAttribute('dir', 'rtl');
    else document.getElementsByTagName('html')[0].setAttribute('dir', 'ltr');
  }

  render() {
    const { children } = this.props;
    return (
      <StyledLayout path={window.location.pathname}>
        {/*<button onClick={this.callToastifyNotification.bind(this)}>Notify</button>*/}
        <ReactHowler
          src={`../../${this.props.volume}`}
          playing={this.props.howler_sound_play}
          loop={this.props.mode === 'Repeated' ? true : false}
        />
        <ToastContainer
          bodyStyle={{ zIndex: '1050' }}
          position="top-right"
          hideProgressBar={true}
          newestOnTop={false}
          closeButton={false}
          rtl={false}
          pauseOnFocusLoss
          draggable={false}
          pauseOnHover
          className="foo"
          style={{ paddingTop: '64px' }}
        />
        <Snackbar
          anchorOrigin={{
            vertical: 'top',
            horizontal: 'center'
          }}
          open={this.state.openOnlineNotification}
          autoHideDuration={6000}
          onClose={this.handleNotificationClose.bind(this)}>
          <Alert
            onClose={this.handleNotificationClose.bind(this)}
            severity={this.state.notificationSeverity}>
            {this.state.notificationMsg}
          </Alert>
        </Snackbar>
        <ContextLayout.Provider
          value={{
            state: this.state,
            fullLayout: layouts['full'],
            verticalLayout: layouts['vertical'],
            horizontalLayout: layouts['horizontal'],
            switchLayout: (layout) => {
              this.setState({ activeLayout: layout });
            },
            switchDir: (dir) => {
              this.setState({ direction: dir });
            }
          }}>
          {children}
        </ContextLayout.Provider>
      </StyledLayout>
    );
  }
}

function mapStateToProps(state) {
  const { orders } = state.orders;
  let orderRows, totalCount;
  if (orders.data) {
    orderRows = orders.data.rows;
    totalCount = orders.data.count;
  }
  return {
    orders: orderRows,
    totalCount,
    locationLoc: state.locations ? state.locations?.location?.data : null,
    volume: state.notificationReducer
      ? state.notificationReducer.volume
      : 'notification_medium.wav',
    mode: state.notificationReducer ? state.notificationReducer.mode : 'Single',
    count: state.notificationReducer ? state.notificationReducer.count : 1,
    handleOneCard: state.notificationReducer ? state.notificationReducer.handleOneCard : false,
    howler_sound_play: state.notificationReducer
      ? state.notificationReducer.howler_sound_play
      : false
  };
}

export default connect(mapStateToProps, {
  getLiveOrders,
  setStateValue,
  setVolume,
  setMode,
  setCount,
  setOneCard,
  setOnPlay
})(Layout);

export { ContextLayout };
