import { toast } from 'react-toastify';
import CONSTANTS from '../constants';
import { customFetch as fetch } from '../helpers/FetchHelper';
import LoginService from './LoginService';

export default {
  updateMenuPriority,
  saveMenu,
  delMenu,
  updateMenuOptions,
  getDummyItem,
  getModifiers,
  getAllModifiers,
  getListMenu
};

async function getDummyItem(loc_id) {
  let { token } = LoginService.getToken();
  let response = await fetch(`${CONSTANTS.API_URL}${CONSTANTS.URLS.MENU.GET_DUMMY_ITEM(loc_id)}`, {
    method: 'GET',
    headers: { ...CONSTANTS.REQUEST_HEADERS, 'X-ACCESS-TOKEN': token }
  });
  response = await response.json();
  if (response) return response;
  throw new Error('Could not fetch Dummy Item');
}

async function getModifiers(loc_id, menu_id) {
  let { token } = LoginService.getToken();
  let response = await fetch(
    `${CONSTANTS.API_URL}${CONSTANTS.URLS.MENU.GET_MODIFIERS(loc_id, menu_id)}`,
    {
      method: 'GET',
      headers: { ...CONSTANTS.REQUEST_HEADERS, 'X-ACCESS-TOKEN': token }
    }
  );
  response = await response.json();
  if (response) return response;
  throw new Error('Could not fetch Dummy Item');
}

async function getListMenu(loc_id) {
  let { token } = LoginService.getToken();
  let response = await fetch(`${CONSTANTS.API_URL}${CONSTANTS.URLS.MENU.GET_LIST_MENU(loc_id)}`, {
    method: 'GET',
    headers: { ...CONSTANTS.REQUEST_HEADERS, 'X-ACCESS-TOKEN': token }
  });
  response = await response.json();
  if (response) return response;
  throw new Error('Could not fetch Dummy Item');
}

async function getAllModifiers(loc_id) {
  let { token } = LoginService.getToken();
  let response = await fetch(
    `${CONSTANTS.API_URL}${CONSTANTS.URLS.MENU.GET_ALL_MODIFIERS(loc_id)}`,
    {
      method: 'GET',
      headers: { ...CONSTANTS.REQUEST_HEADERS, 'X-ACCESS-TOKEN': token }
    }
  );
  response = await response.json();
  if (response) return response;
  throw new Error('Could not fetch Dummy Item');
}

async function updateMenuPriority(loc_id, menus) {
  let { token } = LoginService.getToken();
  let response = await fetch(
    `${CONSTANTS.API_URL}${CONSTANTS.URLS.MENU.BULK_UPDATE_PRIORITY(loc_id)}`,
    {
      method: 'POST',
      body: JSON.stringify(menus),
      headers: { ...CONSTANTS.REQUEST_HEADERS, 'X-ACCESS-TOKEN': token }
    }
  );
  response = await response.json();
  if (response) return response;
  throw new Error('Could not fetch Menu Priorities');
}

async function updateMenuOptions(loc_id, menu_id, optionIds) {
  let { token } = LoginService.getToken();
  let response = await fetch(
    `${CONSTANTS.API_URL}${CONSTANTS.URLS.MENU.MENU_UPDATE_OPTIONS(loc_id, menu_id)}`,
    {
      method: 'POST',
      body: JSON.stringify(optionIds),
      headers: { ...CONSTANTS.REQUEST_HEADERS, 'X-ACCESS-TOKEN': token }
    }
  );
  response = await response.json();
  if (response) return response;
  throw new Error('Could not fetch stats');
}

async function saveMenu(loc_id, item) {
  let { token } = LoginService.getToken();
  let response = await fetch(`${CONSTANTS.API_URL}${CONSTANTS.URLS.MENU.DEFAULT(loc_id)}`, {
    method: 'POST',
    body: item,
    headers: { Accept: CONSTANTS.REQUEST_HEADERS.Accept, 'X-ACCESS-TOKEN': token }
  });
  if (!response.ok) {
    const message = await response.text();
    toast.error(message, {
      position: 'top-center',
      autoClose: 1500,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: 'dark'
    });
    response = null;
  } else {
    response = await response.json();
  }

  if (response) return response;

  throw new Error('Could not save category');
}

async function delMenu(loc_id, menu) {
  let { token } = LoginService.getToken();
  let response = await fetch(`${CONSTANTS.API_URL}${CONSTANTS.URLS.MENU.DEFAULT(loc_id)}/${menu}`, {
    method: 'DELETE',
    headers: { ...CONSTANTS.REQUEST_HEADERS, 'X-ACCESS-TOKEN': token }
  });
  response = await response.json();
  if (response) return response;

  throw new Error('Could not delete category');
}
